:root {
  --bg-color: hsl(59, 63%, 54%);
  --typewriterSpeed: 4s;
  --typewriterCharacters: 24;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;  
  display: grid;
  place-content: center;
  text-align: center;
  background: var(--bg-color);  
}

h3 {
  font-size: clamp(.5rem, 1.5vw + .5rem, 2rem);
  position: relative;
  font-family: "Source Code Pro", monospace;
  position: relative;
  width: max-content;
}

h3::before,
h3::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

h3::before {
  background: var(--bg-color);
  animation: typewriter var(--typewriterSpeed)
    steps(var(--typewriterCharacters)) 1s forwards;
}

h3::after {
  width: 0.125em;
  background: black;
  animation: typewriter var(--typewriterSpeed)
      steps(var(--typewriterCharacters)) 1s forwards,
    blink 750ms steps(var(--typewriterCharacters)) infinite;
}

.subtitle {
  color: hsl(0 0% 0% / 0.7);
  font-size: 2rem;
  font-weight: 400;
  opacity: 0;
  transform: translateY(3rem);
  animation: fadeInUp 2s ease calc(var(--typewriterSpeed) + 2s) forwards;
}

.logo-container {
  position: relative;
  animation: dropInLogo 6s ease-in-out forwards;  
}

.logo {  
  width: 400px;
  height: 400px;  
}

.main-container {
  background-size: cover;
  background-position: center;
}

.main-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes blink {
  to {
    background: transparent;
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dropInLogo {
  from {top: -300px;}
  to {top: 10px;}
}

#yt-link {
  position: absolute;
  bottom: 2em;
  width: 100%;
  color: hsl(0 0 0 / 0.7);

  &:hover,
  &:focus {
    color: teal;
  }
}


.search {
  outline: none;
  padding: 20px 7%;
  border-radius: 20px;
  border: none;
  margin-bottom: 5%;
  background: rgba(250, 250, 250, 0.85);
}

/*
.city {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 8%;
  border-radius: 20px;
  background: rgba(250, 250, 250, 0.85);
  box-shadow: 10px 10px 5px 0px rgba(15, 15, 15, 0.404);
}

p {
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.sub-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1e2432;
  text-align: center;
}

.city sup {
  font-size: 0.5em;
}

.city-name {
  font-size: 2em;
}

.sub-title sup {
  padding: 0.2em 0.6em;
  margin-left: 0.2em;
  border-radius: 30px;
  color: #fff;
  background: #ff8c00;
}

.city-icon {
  margin-top: 10px;
  width: 400px;
  height: 400px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .search {
      padding: 20px 15%;
  }

  .city {
      padding: 40px 20%;
  }
}




.add-button {
  position: absolute;
  top: 1px;
  left: 1px;
  outline: none;
  padding: 5px 7%;
  border-radius: 5px;
  border: none;
  margin-bottom: 5%;
  background: rgba(250, 250, 250, 0.85);
}  */

.button {
  background-color: #e9993f; 
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button-install {
  background-color: #e9993f; 
  color: white; 
  border: 2px solid #e9993f;
}

.button-install:hover {
  background-color: white;
  color: #e9993f;
}


.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background: pink;
  height: 50vh;  
  padding:0.5em;
  margin: 25vh auto 0;
}
.course-card {
  width: min-content;
  background: lightblue;
  margin: 0.5em;
  
}